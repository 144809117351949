<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button type="primary" @click="showAddQualityAtandardDialog"
            >添加检测项目</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!-- 输入框，尾部带按钮 -->
          <el-input
            placeholder="请输入检测项目名称"
            v-model="queryInfo.projectName"
            clearable
            @clear="getQualityAtandardList"
            @change="getQualityAtandardList"
          >
            <el-button slot="append" @click="getQualityAtandardList"
              >搜索</el-button
            >
          </el-input>
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table :data="qualityAtandardList" stripe style="width: 100%" border>
        <!-- 第一列商户id -->
        <el-table-column label="项目id" width="100" aligin="center" prop="id">
        </el-table-column>
        <!-- 第二列项目名称 -->
        <el-table-column label="项目名称" aligin="center" prop="projectName">
        </el-table-column>
        <!-- 第三列国家标准值 -->
        <el-table-column label="国家标准值" aligin="center" prop="standard">
        </el-table-column>
        <!-- 第四列国家标准号 -->
        <el-table-column
          label="国家标准号"
          aligin="center"
          prop="standardNumber"
        >
        </el-table-column>
        <!-- 第五列操作 -->
        <el-table-column label="操作" width="200" aligin="center">
          <!-- 修改按钮 -->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="showEditQualityAtandardDialog(scope.row)"
              >修改</el-button
            >
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeQualityAtandardById(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加检测项目对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addQualityAtandardDialogVisible"
      width="30%"
      @close="clearAddQualityAtandard"
    >
      <el-form
        :model="qualityStandardForm"
        :rules="qualityAtandardRules"
        ref="qualityAtandardFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="项目名称" prop="projectName">
          <el-input
            v-model="qualityStandardForm.projectName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="国家标准值" prop="standard">
          <el-input v-model="qualityStandardForm.standard" clearable></el-input>
        </el-form-item>
        <el-form-item label="国家标准号" prop="standardNumber">
          <el-input
            v-model="qualityStandardForm.standardNumber"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQualityAtandardDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 请求入参
      queryInfo: {
        projectName: '', // 项目名称
        standard: '', // 国家标准值
        standardNumber: '', // 国家标准号
        pageNo: 1,
        limit: 15
      },
      // 检测项目数组
      qualityAtandardList: [],
      // 总数
      total: 0,
      // 新增对话框，默认false
      addQualityAtandardDialogVisible: false,
      // 新增和修改公用一个对话框
      title: '',
      submitType: '',
      // 新增所需要的的参数
      qualityStandardForm: {
        marketId: '',
        projectName: '', // 项目名称
        standard: '', // 国家标准值
        standardNumber: '' // 国家标准号
      },
      // 修改所需要的项目id
      projectId: '',
      // 校验规则
      qualityAtandardRules: {
        projectName: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        standard: [
          { required: true, message: '请输入国家标准值', trigger: 'blur' }
        ],
        standardNumber: [
          { required: true, message: '请输入国家标准号', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 请求食品安全检测项目列表
    this.getQualityAtandardList()
  },
  methods: {
    // 定义请求检测项目方法
    async getQualityAtandardList() {
      const { data: res } = await this.$http.get(
        'food-test/qualityStandard/list',
        {
          params: this.queryInfo
        }
      )
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      // 请求成功后进行赋值
      this.qualityAtandardList = res.data.data
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize
      this.getQualityAtandardList()
    },
    // 页码变化，调用接口
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.getQualityAtandardList()
    },
    // 打开新增对话框
    showAddQualityAtandardDialog() {
      this.title = '新增检测项目'
      this.submitType = 'add'
      this.addQualityAtandardDialogVisible = true
    },
    // 打开修改对话框
    showEditQualityAtandardDialog(row) {
      // 目前还没有id，有bug
      this.title = '修改检测项目'
      this.submitType = 'edit'
      // 赋值
      this.qualityStandardForm = row
      // 赋值id，用来修改
      this.projectId = row.id
      this.addQualityAtandardDialogVisible = true
    },
    // 提交操作
    async submit() {
      this.qualityStandardForm.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      this.$refs.qualityAtandardFormRef.validate(async valid => {
        if (!valid) return
        // 可以发起网络请求
        if (this.submitType === 'add') {
          const { data: res } = await this.$http.post(
            'food-test/qualityStandard',
            this.qualityStandardForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加检测项目成功')
          this.getQualityAtandardList()
          this.addQualityAtandardDialogVisible = false
        } else {
          const { data: res } = await this.$http.put(
            `food-test/qualityStandard/${this.projectId}`,
            this.qualityStandardForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改检测项目成功')
          this.getQualityAtandardList()
          this.addQualityAtandardDialogVisible = false
        }
      })
    },
    // 关闭对话框，清空参数
    clearAddQualityAtandard() {
      this.qualityStandardForm = {}
      this.getQualityAtandardList()
    },
    // 删除操作
    async removeQualityAtandardById(id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该检测项目，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `food-test/qualityStandard/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除检测项目失败')
      }
      this.$message.success('删除检测项目成功')
      this.getQualityAtandardList()
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  margin-right: 20px;
}
</style>
